<template>
  <section class="noselect flex justify-between h-10 sticky top-0 z-20">
    <div class="navbar bg-base-300 bg-opacity-70 backdrop-blur-md">
      <div class="navbar-start">
        <div class="dropdown">
          <label tabindex="0" class="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            class="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <router-link to="/about"
                ><i class="las la-question"></i> Hakkında</router-link
              >
            </li>

            <li>
              <router-link to="/timeline"
                ><i class="las la-hourglass-half"></i> Tarihi
                Yaklaşanlar</router-link
              >
            </li>
            <li tabindex="0" v-if="isLoggedIn">
              <details>
                <summary><i class="las la-edit"></i> Editör</summary>
                <ul class="p-2">
                  <li>
                    <router-link to="/editor/check"
                      ><i class="las la-user-check"></i> Onay
                      bekleyenler</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/editor/analysis"
                      ><i class="las la-edit"></i> Analiz
                      gerekenler</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/editor/trash"
                      ><i class="las la-broom"></i> Temizlik</router-link
                    >
                  </li>
                </ul>
              </details>
            </li>
          </ul>
        </div>
        <!-- <a class="btn btn-ghost normal-case text-xl">Savundun!</a> -->
        <router-link style="position: relative" to="/">
          <div class="avatar">
            <div class="w-12 ml-2 rounded">
              <img
                :src="logo"
                alt="Kağıt parçası tutan bir el ve Savundun! yazısı."
              />
            </div>
          </div>
        </router-link>
      </div>
      <div class="navbar-center hidden lg:flex">
        <ul class="menu menu-horizontal px-1 flex gap-2">
          <li>
            <router-link to="/about"
              ><i class="las la-question"></i> Hakkında</router-link
            >
          </li>

          <li>
            <router-link to="/timeline"
              ><i class="las la-hourglass-half"></i> Tarihi
              Yaklaşanlar</router-link
            >
          </li>
          <li tabindex="0" v-if="isLoggedIn">
            <details>
              <summary class="capitalize">
                <i class="las la-edit"></i> {{ user }}
              </summary>
              <ul class="p-2">
                <li>
                  <router-link to="/editor/check"
                    ><i class="las la-user-check"></i> Onay
                    bekleyenler</router-link
                  >
                </li>
                <li>
                  <router-link to="/editor/analysis"
                    ><i class="las la-edit"></i> Analiz gerekenler</router-link
                  >
                </li>
                <li>
                  <router-link to="/editor/trash"
                    ><i class="las la-broom"></i> Temizlik</router-link
                  >
                </li>
                <li>
                  <div
                    v-if="isLoggedIn"
                    onclick="{localStorage.setItem('token', null); location.reload()}"
                  >
                    <i class="las la-running"></i>
                    Çıkış yap
                  </div>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
      <div class="navbar-end">
        <router-link class="btn btn-primary" to="/add">YENİ EKLE</router-link>
      </div>
    </div>

    <!-- <section class="flex align-center">
      <router-link class="navItem" v-if="!isLoggedIn" to="/about"
        ><h2>
          <i class="las la-question"></i> <span>Nedir?</span>
        </h2></router-link
      >
      <router-link class="navItem" to="/timeline"
        ><h2>
          <i class="las la-hourglass-half"></i> <span>Tarihi yaklaşan</span>
        </h2></router-link
      >
      <router-link
        class="navItem editorBtn"
        v-if="isLoggedIn"
        to="/editor/check"
        ><h2>
          <i class="las la-user-check"></i> <span>Onay</span>
        </h2></router-link
      >
      <router-link
        class="navItem editorBtn"
        v-if="isLoggedIn"
        to="/editor/analysis"
        ><h2><i class="las la-edit"></i> <span>Analiz</span></h2></router-link
      >
      <router-link
        class="navItem editorBtn"
        v-if="isLoggedIn"
        to="/editor/trash"
        ><h2>
          <i class="las la-broom"></i> <span>Temizlik</span>
        </h2></router-link
      >
      <div
        class="navItem editorBtn"
        v-if="isLoggedIn"
        onclick="{localStorage.setItem('token', null); location.reload()}"
      >
        <h2>
          <i class="las la-running"></i>
          <span>Çıkış</span>
        </h2>
      </div>
    </section> -->
  </section>
</template>

<script>
export default {
  props: {
    isLoggedIn: Boolean,
  },
  data() {
    return {
      user: null,
      logo: require("@/assets/logo.svg"),
    };
  },
  mounted() {
    this.user = localStorage.getItem("username") || null;
  },
};
</script>

<style lang="scss" scoped>
.userBadge {
  position: absolute;
  left: 12px;
  top: 15px;
  margin: 0 auto;
  font-size: 12px;
  color: #fbc739;
  pointer-events: none;
}
.headerNav {
  position: relative;
  display: flex;
  align-items: flex-end;

  .navItem {
    cursor: pointer;
    padding: 0 5px;
    margin: 0 5px;
    border-radius: 5px;
    font-weight: lighter;
    font-size: x-small;
    background-color: rgba($color: #000000, $alpha: 0.05);
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.6);
    }
  }
  .editorBtn {
    color: #fbc739;
  }
}
@media only screen and (max-width: 900px) {
  .headerNav {
    span {
      display: none;
    }
  }
}
</style>
