import { createRouter, createWebHashHistory } from "vue-router";
// createWebHashHistory
// import HomePage from '../App.vue'

const routes = [
  {
    path: "/",
    name: "homepage",
    component: () => import("../pages/HomePage.vue"),
  },
  {
    path: "/add",
    name: "add",
    component: () => import("../pages/AddProfile.vue"),
  },
  {
    path: "/profile/:id/:entry",
    name: "profileEntryFull",
    component: () => import("../pages/GetEntry.vue"),
  },
  {
    path: "/profile/:id",
    name: "profile",
    component: () => import("../pages/GetProfile.vue"),
  },
  {
    path: "/editor",
    name: "editor",
    component: () => import("../pages/EditorMode.vue"),
  },
  {
    path: "/editor/check",
    name: "check",
    component: () => import("../pages/PostApproval.vue"),
  },
  {
    path: "/editor/analysis",
    name: "analysis",
    component: () => import("../pages/NeedAnalysis.vue"),
  },
  {
    path: "/editor/trash",
    name: "trash",
    component: () => import("../pages/TrashBin.vue"),
  },
  {
    path: "/timeline",
    name: "timeline",
    component: () => import("../pages/TimelinePage.vue"),
  },
  //  {
  //    path: '/alleges',
  //    name: 'alleges',
  //    component: () => import('../../.tmp/AllegesPage.vue')
  //  },
  {
    path: "/about",
    name: "about",
    component: () => import("../pages/AboutPage.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    component: () => import("../pages/RulesGuidelines.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
