import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueLazyLoad from "vue3-lazyload";
import "/public/N.css";

const app = createApp(App);

const debug = false;
window.APIENDPOINT = debug
  ? "http://localhost:4000"
  : "https://api.savundun.com";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

app.use(router);
app.use(VueLazyLoad, {
  // options...
});
app.mount("#app");
