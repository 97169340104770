<template>
  <section
    class="p-1 bg-gray-600 bg-opacity-20 backdrop-filter backdrop-blur-sm w-full h-full flex justify-center"
  >
    <div class="loginContainer">
      <section v-if="registering.actv">
        <form id="register-form" @submit.prevent="register" method="post">
          <input
            class="input-bordered input-warning w-full max-w-xs"
            v-model="registering.name"
            type="username"
            placeholder="Kullanıcı adı"
            required
          />
          <input
            class="input-bordered input-warning w-full max-w-xs"
            v-model="registering.pass"
            type="password"
            placeholder="Şifre"
            required
          />
          <input
            class="input-bordered input-warning w-full max-w-xs"
            v-model="registering.invt"
            type="text"
            placeholder="Davet kodu"
            required
          />
          <span v-if="response">{{ this.response }}</span>
          <button class="btn btn-primary" type="submit">Kayıt</button>
        </form>
      </section>
      <section class="flex flex-col gap-2" v-else>
        <input
          class="input-bordered input-warning w-full"
          v-model="username"
          type="text"
          placeholder="Kullanıcı adı"
        />
        <input
          class="input-bordered input-warning w-full"
          v-model="password"
          type="password"
          placeholder="Şifre"
        />
        <button class="btn btn-primary" @click="login">Giriş</button>
      </section>
      <br />
      <!-- <button style="width: 20%;" @click="registering.actv = !registering.actv">Yeni kayıt</button> -->
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      response: "",
      registering: {
        actv: false,
        name: "",
        pass: "",
        invt: "",
      },
    };
  },
  methods: {
    login() {
      const loginData = {
        username: this.username,
        password: this.password,
      };

      fetch(window.APIENDPOINT + "/usr/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Login failed");
          }
        })
        .then((data) => {
          const token = data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("username", loginData.username);
          this.$emit("login-success");
        })
        .catch((error) => {
          console.error("Login failed:", error);
        });
    },
    async register() {
      const regData = {
        username: this.registering.name,
        password: this.registering.pass,
        invitation: this.registering.invt,
      };
      await fetch(window.APIENDPOINT + "/usr/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(regData),
      })
        .then((response) => {
          if (response.ok) {
            this.response = "Başarılı!";
          } else {
            this.response = "Başarısız!";
          }
        })
        .catch((error) => {
          console.error("Registration error:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
