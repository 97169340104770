<template>
  <div v-if="checked" id="editorContainer">
    <EditorMode @login-success="handleLoginSuccess" :isLoggedIn="isLoggedIn" />
  </div>
  <div @click="this.checked = true" class="logoBar"></div>

  <NavBar :isLoggedIn="isLoggedIn" />
  <div class="mt-10 w-full xl:w-4/6 mx-auto">
    <router-view :isLoggedIn="isLoggedIn"></router-view>
  </div>

  <FooterBar />
</template>

<script>
import EditorMode from "@/pages/EditorMode.vue";
import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/FooterBar.vue";
export default {
  name: "App",
  components: {
    EditorMode,
    NavBar,
    FooterBar,
  },
  data() {
    return {
      profiles: [],
      isLoggedIn: false,
      editorKey: ["HOME"],
      checked: false,
      logo: require("@/assets/logo.svg"),
    };
  },
  methods: {
    handleLoginSuccess() {
      this.isLoggedIn = true;
      this.checked = false;
      this.editorKey = ["HOME", "HOME"];
    },
    handleKeydown(event) {
      const key = event.key.toUpperCase();
      if (this.checked) {
        if (key === "ENTER") {
          this.checked = false;
        }
      } else {
        if (key === this.editorKey[0]) {
          this.editorKey.shift();
          if (this.editorKey.length === 0) {
            this.checked = true;
          }
        }
      }
    },
  },
  computed: {
    homepage() {
      return this.$route.path === "/";
    },
  },
  async mounted() {
    window.addEventListener("keydown", this.handleKeydown);

    this.user = localStorage.getItem("username") || "User";

    try {
      const response = await fetch(window.APIENDPOINT + "/api/auth", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) this.isLoggedIn = true;
    } catch (error) {
      console.error("Error while checking authentication status:", error);
      return (this.isLoggedIn = false);
    }
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Lexend";
  src: url("assets/fonts/Manrope-Regular.ttf");
}

@font-face {
  font-family: "Emoji";
  src: url("assets/fonts/NotoColorEmoji-Regular.ttf");
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #dbdbdb;
  background-color: #101010;
  min-height: 100vh;
  letter-spacing: 2px;
}

#editorContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999;

  left: 50%;
  transform: translateX(-50%);
}

.link {
  color: #fbc739;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: normal;
  padding: 0;
}

#header {
  display: flex;
  padding: 5px 10%;
  align-items: center;
  .headerLeft {
    gap: 10px;
  }
  img {
    width: 68px;
  }

  #addNewAllege {
    min-width: 5rem;
    position: relative;
    margin: 0 !important;

    &::before {
      content: "Ekle";
      color: black;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      font-size: x-large;
      font-weight: bold;
      z-index: 2;
    }
  }
}

.guideHeader {
  padding: 10px;
  background-color: #ffd003;
  color: black;
  border-radius: 5px;
}

#banner {
  background-color: rgb(34, 34, 34);
  padding: 10px 5%;
  line-height: normal;
  text-align: center;
  z-index: 1;
}

.emoji {
  font-family: "Emoji";
}

.logoBar {
  position: absolute;
  z-index: 9999;
  top: 60px;
  width: 100%;
  text-align: center;
  color: transparent;
  &::after {
    content: "Giriş";
  }
}

.extrusion {
  position: relative;
  height: 40px;
  perspective: 800px;
}

.extrusion:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2d2d;
  border-radius: 5px;
  transform-origin: bottom;
  transform: rotateX(70deg);
}

.navLogoItem {
  &:hover {
    text-decoration: underline;
  }
}

.headerNavMobile {
  display: none;
}
@media only screen and (max-width: 900px) {
  #contentBody {
    width: 100% !important;
  }
  .forDesktop {
    display: none;
  }
}

.taskClose {
  z-index: 2;
  display: flex;
  padding: 5px;
  position: absolute;
  &:hover {
    cursor: pointer;
    background-color: #050505;
    border-radius: 5px;
  }
}

.singlecontentContainer {
  &:hover {
    filter: brightness(1.05);
  }
}

#loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 5px;
}

// form {
//   padding: 1rem;
//   width: 100%;
//   textarea {
//     min-height: 72px;
//     max-height: 256px;
//     border-radius: 5px;
//     border: 1px solid rgba($color: #fff, $alpha: 0.2);
//   }
//   .checkbox {
//     width: auto;
//     vertical-align: middle;
//   }

//   @media only screen and (max-width: 900px) {
//     width: 100%;
//   }
// }
// input:not([type="checkbox"]),
// textarea,
// select,
// button {
//   padding: 5px;
//   width: 100%;
//   border: 0;
//   height: 40px;

//   margin: 12px auto;
//   margin-top: 7px;
//   border-radius: 5px;

//   background-color: #1f1f1f;
//   color: white;

//   backdrop-filter: blur(10px);
//   &::placeholder {
//     color: rgba($color: #fff, $alpha: 0.5);
//   }

//   transition: all 100ms linear;
//   &:hover {
//     filter: brightness(1.1);
//     color: #fbc739;
//   }
//   &:focus {
//     filter: brightness(1.1);
//     color: white;
//     outline: none !important;
//   }
// }
// button,
// input[type="submit"] {
//   background-color: #222;
//   border-radius: 5px;
//   width: 100%;
//   color: white;

//   backdrop-filter: blur(10px);
//   background-color: rgba(255, 255, 255, 0.1);
//   transition: all 100ms linear;
//   &:hover {
//     cursor: pointer;
//     filter: brightness(1.1);
//     color: #fbc739;
//   }
//   &:focus {
//     filter: brightness(1.1);
//     color: black;
//     outline: none !important;
//   }
// }

// button {
//   background-color: #fbc739;
//   color: black !important;
// }

.editorButton {
  height: 22px;
}

form {
  @apply w-96 m-auto lg:w-full py-5;
}

label {
  @apply label;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

::placeholder {
  color: gray !important;
}

input:not([type="file"]):not([type="checkbox"]) {
  @apply input input-bordered w-full rounded-md;

  padding: 10px !important;
}

form select {
  @apply select min-w-full rounded-md;
}

textarea {
  @apply textarea w-full input-bordered rounded-md;
  min-height: 5rem;
  padding: 10px !important;
}

button {
  @apply btn btn-primary;
}

// .btn {
//   border-radius: 1rem !important;
// }

.textInput {
  color: white;
  border: 0;
  border-radius: 5px;
  line-height: 30px;
  padding: 5px 10px;
}
</style>
