<template>
  <footer class="footer footer-center p-10 mt-10 text-primary-content">
    <aside>
      <router-link style="position: relative" to="/">
        <div class="avatar">
          <img
            :src="logo"
            alt="Kağıt parçası tutan bir el ve Savundun! yazısı."
          />
        </div>
      </router-link>
      <p class="text-gray-500">
        Evrim Ağacı © {{ year }} - Tüm hakları saklıdır.
      </p>
    </aside>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      logo: require("@/assets/logo.svg"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
